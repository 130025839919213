
import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink, split } from '@apollo/client';
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from '@apollo/client/utilities';
import { setContext } from '@apollo/client/link/context';
import { SubscriptionClient } from "subscriptions-transport-ws";


// HTTP Link
const httpLink = new HttpLink({
    uri: `${process.env.REACT_APP_WEBSOCKET_DOMAIN}/v1/graphql`,
  });
  
  // Adds Authentication Headers on HTTP as well as was requests
  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        "Authorization":`Bearer ${process.env.REACT_APP_WEBSOCKET_BEARER_TOKEN}`
      },
    };
  });
  
//   // WebSocket Link
//   const wsLink = new WebSocketLink({
//     uri: `ws://localhost:8080/v1/graphql`,
//     options: {
//       reconnect: true,
//       lazy: true,
//     },
//   });
const wsLink = new WebSocketLink(
  new SubscriptionClient(`${process.env.REACT_APP_WEBSOCKET_WS_DOMAIN}/v1/graphql`, {
      reconnect: true,
      timeout: 300000,
      reconnectionAttempts: Infinity,
      reconnectionDelay: (attempt) => Math.min(1000 * 2 ** attempt, 60000),
      connectionParams: {
          headers: {
            "Authorization":`Bearer ${process.env.REACT_APP_WEBSOCKET_BEARER_TOKEN}`
          }
      }
  })
);
  
  // Send query request based on the type definition
  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    authLink.concat(wsLink),
    authLink.concat(httpLink)
  );
  
  // Apollo Client
  export const client = new ApolloClient({
    link,
    cache: new InMemoryCache(),
  });
